import React, { useContext } from 'react'
import { AuthContext } from 'src/contexts/AuthContext'
import { Popover } from 'antd'
import { useHistory } from 'react-router-dom'
import { UserOutlined, ExportOutlined } from '@ant-design/icons'
import Logo from 'src/assets/images/logo.png'
import './index.less'

const Header = () => {
  const history = useHistory()
  const { user, logout } = useContext(AuthContext)

  const editCode = () => {
    history.replace('/user/editcode')
  }

  return (
    <div className="main-header w-full fixed flex justify-between items-center z-10">
      <div className="brand flex justify-start items-center">
        <img className="brand-img" src={Logo} alt="赢之营创新研究院" />
        <div className="brand-info">
          <div className="tip-1">
            <div>赢加Saas 数字化服务</div>
          </div>
          <p className="devide-line"></p>
          <p className="tip-2">后台管理系统</p>
        </div>
      </div>
      <div className="user-container">
        <div className="userbox flex justify-between items-center gap-3">
          <div className="company">{user?.company}</div>
          <Popover overlayClassName="edit-code-con" content={
            <div className="edit-code" onClick={editCode}>修改密码</div>
          }>
            <div className="user-btn flex items-center">
              <UserOutlined style={{fontSize: '18px'}} />
              <span>{user?.name}</span>
            </div>
          </Popover>
          <div
            className="user-btn flex items-center"
            onClick={() => logout()}
          >
            <ExportOutlined style={{fontSize: '18px'}} />
            <span>退出</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
